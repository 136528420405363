import { Input, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

export default function Ingreso({theDatos}) {
  const [theTime, settheTime] = useState('')
  const [theDate, settheDate] = useState('')
  const [theCalculo, settheCalculo] = useState('')

  let [putInterval, setputInterval] = useState()//setinterval para clean en useEffect
  const [conglomerado, setConglomerado] = useState({
    'operario': theDatos.operario,
    'producto':'XPOLLO TRUTRO CUARTO USA',
    'maquina': theDatos.linea,
    'of': theDatos.of,
    'fecha': theDate,
    'hora':theTime,
    'kgin':'',
    'kgout':'',
    'porinyect':'',
    'presionequipo':'',
    'velcinta':'',
    'golpxmin':'',
    'tempsalm':'',
    'filtro':'',
    'supervisor':''
  })

  const HandleSetConglomerado = async (e) => {
  //  console.log(e.target.name, e.target.value)
    setConglomerado({
    ...conglomerado,[e.target.name]: e.target.value
    })
    if(e.target.name === 'kgin' || e.target.name === 'kgout'){
      await HandleCalculatePorInyect()
    }
  }
  useEffect(() => {
    HandleCalculatePorInyect()
    return () => {
    }
  }, [conglomerado])
  
  /*
    Funcion que calcula el porcentaje de injecccion basaso en dos parametros
    kgin = float
    kgout = float
    retorna e = float (0.00%)
  */
  const HandleCalculatePorInyect = async () => {
    let a = conglomerado.kgin
    let b = conglomerado.kgout
    let c = (b-a)
    let d = (c*100)/a
    let e = Number.parseFloat(d).toFixed(2)
    // console.log(a,b,c,d,e)
    if(!d)settheCalculo('--%')
    else{
      //console.log('Calculo de inyeccion: ', d)
      settheCalculo(e+'%')
    }
  }

  /*funcion que muestra la hora actual
  retorna la hora en formato para input time (yyyy-mm-dd)
  */
  const GetTime = async () => {
    putInterval = setInterval(() => {
      let f = new Date()
      let hh = f.getHours()>9?f.getHours():'0'+f.getHours()
      let mm = f.getMinutes()>9?f.getMinutes():'0'+f.getMinutes()
      let ss = f.getSeconds()>9?f.getSeconds():'0'+f.getSeconds()
      let DD = f.getDate()>9?f.getDate():'0'+f.getDate()
      let M_M = (f.getMonth()+1)>9?(f.getMonth()+1):'0'+(f.getMonth()+1)
      let YYYY = f.getFullYear()
      settheTime(hh+":"+mm+":"+ss)
      //console.log(YYYY+'-'+M_M+'-'+DD+'//'+ss)
      settheDate(YYYY+'-'+M_M+'-'+DD)
    }, 1000);
  }

  useEffect(() => {
    GetTime()
    return () => {
      clearInterval(putInterval)
    }
  }, [])
  //console.log(theDatos)

  const HandleSendDataToServer = async () => {
    console.log(theTime, theDate)
    setConglomerado({
      ...conglomerado, hora: theTime, fecha: theDate
    })

  }

  return (
    <>
      <div className='container-fluid mb-3'>
        <div className='row bg-ariztia d-flex justify-content-end'>
          <div className='text-warning'>
            <span>Usuario: {theDatos.operario}</span>
          </div>
          <div className=''>
            <img src='/img/logo_ariztia.png' height="60px" />
          </div>
        </div>
      </div>

      <div className='container fondo rounded-3 py-5 mb-3'>
        <div className='row '>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12 col-md-6 py-2'>
                <TextField type={'text'} name="maquina" disabled fullWidth label="MAQUINA" value={conglomerado.maquina} onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField type={'text'} name="producto" disabled fullWidth label="PRODUCTO" value={conglomerado.producto} onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField type={'date'} name="fecha" disabled fullWidth label="FECHA" value={theDate} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField type={'time'} name="hora" disabled fullWidth label="HORA" value={theTime} variant="outlined" size='small' />
              </div>
            </div>
          </div>
          <div className='col-12 fondo'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='col-12 py-2'>
                  <TextField type='number' fullWidth label="KG ENTRADA" value={conglomerado.kgin} name="kgin" onChange={(e)=>{HandleSetConglomerado(e)}} variant="outlined" size='small' />
                </div>
                <div className='col-12 py-2'>
                  <TextField type='number' fullWidth label="KG SALIDA" value={conglomerado.kgout} name="kgout" onChange={(e)=>{HandleSetConglomerado(e)}} variant="outlined" size='small' />
                </div>
                <div className='col-12 py-2'>
                  <TextField type={'text'} disabled fullWidth label="% INYECCION" value={theCalculo} name="porinyect" variant="outlined" size='small' />
                </div>
              </div>
              <div className='col-12 col-md-6 text-center'>
                <img src='/img/logo_ariztia.png'/>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='row'>
              <div className='col-12 col-md-6 py-2'>
                <TextField id="outlined-basic" fullWidth label="PRESION EQUIPO (bar)" value={conglomerado.presionequipo} name="presionequipo" onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField id="outlined-basic" fullWidth label="VELOCIDAD CINTA" value={conglomerado.velcinta} name="velcinta" onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField id="outlined-basic" fullWidth label="GOLPES POR MINUTO" value={conglomerado.golpxmin} name="golpxmin" onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField id="outlined-basic" fullWidth label="TEMPERATURA SALMUERA" value={conglomerado.tempsalm} name="tempsalm" onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField id="outlined-basic" fullWidth label="SUPERVISOR" value={conglomerado.supervisor} name="supervisor" onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
              <div className='col-12 col-md-6 py-2'>
                <TextField type={'time'} id="outlined-basic" fullWidth label="HORA ULTIMO LAVADO FILTRO" value={conglomerado.filtro} name="filtro" onChange={(e)=>HandleSetConglomerado(e)} variant="outlined" size='small' />
              </div>
            </div>
          </div>
          <div className='col-12 text-center pt-3'>
            <button className='btn btn-success w-75' onClick={()=>HandleSendDataToServer()}>GUARDAR DATOS</button>
          </div>
        </div>
      </div>
    </>
  )
}
