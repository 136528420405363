import React, { useContext, useEffect, useState } from 'react'
import './principal.styles.css'

import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Seleccion from './ingreso/Seleccion.view';
import Login from './session/Login.view';
import { Link } from 'react-router-dom';
import { AuthContext } from '../App';


export default function Main({autentication}) {
  
  let operario = sessionStorage.getItem('operario') || ''
  let linea = sessionStorage.getItem('linea') || ''
  let MAQUINA = sessionStorage.getItem('MAQUINA') || ''
  //console.log('from sessionstate',operario, linea)
  const [data, setData] = useState({
    operario: operario,
    MAQUINA: MAQUINA,
    linea: linea
  })

  const [complete, setComplete] = useState(true)

  const handleSetData = async (e) => {
    let indice = e.target.selectedIndex
    
    console.log(e.target.value, e.target.name, )
    
    if(e.target.name === 'MAQUINA') {
      await sessionStorage.setItem('MAQUINA', e.target.value)
      await sessionStorage.setItem('linea', e.target.options[indice].text)
      await setData({
        ...data,['linea']:e.target.value
        })
        await setData({
          ...data,['MAQUINA']:e.target.value
          })
    }else{
      await setData({
      ...data,[e.target.name]:e.target.value
      })
      await sessionStorage.setItem(e.target.name, e.target.value)
    }
    HandleNextModule()
  }

  const HandleNextModule = () => {
    if(data.linea !== '' && data.operario !== '')setComplete(false)
  }
  useEffect(() => {
    HandleNextModule()
  }, [data])

  return (
    <>
      <div className='container-fluid'>
        <div className='row fullhd'>
          <div className='centro w-75'>
            <div className='row '>
              <div className='centro text-center col-12 col-md-6'>
                <img src="/img/logo_ariztia.png" alt="" />
              </div>
              <div className='fondo col-12 col-md-6 divmenu1'>
                <div className='row py-5'>
                  <div className='col-12 '>
                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Operario"
                      size='small'
                      fullWidth
                      name='operario'
                      value={data.operario}
                      onChange={handleSetData}
                    >
                      <MenuItem value='0'> opcion cero</MenuItem>
                      <MenuItem value='1'> opcion uno</MenuItem>
                      <MenuItem value='2'> opcion dos</MenuItem>
                      <MenuItem value='3'> opcion tres</MenuItem>
                      <MenuItem value='4'> opcion cuatro</MenuItem>
                    </TextField>
                  </div>
                  <div className='col-12 pt-3'>
                    <select
                      id="outlined-select-currency"
                      name='MAQUINA'
                      value={data.MAQUINA}
                      onChange={handleSetData}
                    >
                      <option value='IQF'> CFS 450 IQF 1</option>
                      <option value='IQF'> CFS 650 IQF 4</option>
                      <option value='ISHIDA'> CFS 650 TRUTRO NORTE</option>
                      <option value='ISHIDA'> CFS 650 TRUTRO SUR</option>
                      <option value='INYEC'> METALQUIMIA</option>
                    </select>
                  </div>
                  <div className='col-12 pt-3'>
                    <Link to={"/seleccion"} >
                      <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        disabled={complete}
                      >
                        Ingresar
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        autentication?null
        :<a href='/login' className='btn btn-sm btn-info btn-login'>Login <VpnKeyOutlinedIcon /></a>
      }
      

    </>
  )
}
