import React, { useEffect, useState } from 'react'
import './cargaArchivo.css'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { url } from '../../links/Url.link';

export default function CargaArchivo({metadata}) {
  const [opline, setopline] = useState({
    operario: '',
    linea: '',
    file:''
  })
  const [archivo, setArchivo] = useState({})
  //console.log(metadata)
  const HandleChangeLoadFile = async (e) => {
    setArchivo(e.target.files[0])
    setopline({
      ...opline,
      [e.target.name]: e.target.value.split('\\').pop()
    })
  }

  useEffect(() => {
    let operario = sessionStorage.getItem('operario') || ''
    let linea = sessionStorage.getItem('linea') || ''
    setopline({
      ...opline,
      operario: operario,
      linea: linea
    })
  }, [])

  const HandleSendFileSRV = async () => {
    let formData = new FormData()
    formData.append('file', archivo)
    const fetsh = await fetch(url+'/archivalo',{
      method: 'POST',
      // headers: {
      //   'authorization': "paico 2021",
      //   'Content-Type': 'multipart/form-data'
      // },
      body: formData
    })

    const request = await fetsh.json()
    if(request.success){
      console.log()
      window.alert(`Se Ingreso un total de ${request.body.lineas} datos.\n (se recomineda borrar los datos del archivo y actualizarlos par en fururas cargas de datos no se dupliquen.)`)
    }
  }
  
  return (
    <>
    <div className='container-fluid'>
        <div className='row bg-ariztia d-flex justify-content-end'>
          <div className='text-warning'>
            <span>Usuario: {opline.operario}</span>
          </div>
          <div className=''>
            <img src='/img/logo_ariztia.png' height="60px" />
          </div>
        </div>
      </div>
    {/* <div className='container fondo rounded-3 mt-5'> */}
      {/* <div className='row'> */}
        <div className='outder'>
          <div className='iner fondo'>
            <div className='container-fluid'>
              <div className='row outdermini'>
                <div className="col-12 col-md-6 pt-3 text-center">
                  <label htmlFor='file' id="secondfile" className='btn btn-sm btn-info'>{
                    opline.file===''? 
                    <>Seleccionar archivo <AttachmentIcon /></>
                    :<><Inventory2OutlinedIcon /> {opline.file}</>
                  }</label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    className="form-control"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    required
                    onChange={(e)=>HandleChangeLoadFile(e)}
                  />
                </div>
                <div className="col-12 col-md-6 text-center">
                  {
                    opline.file===''?
                    <button className='btn btn-sm btn-success' disabled>Subir Archivo</button>
                    :<button className='btn btn-sm btn-success' onClick={()=>HandleSendFileSRV()}>Subir Archivo</button>
                  }
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* // </div> */}
    {/* // </div> */}
    <p onClick={()=>{console.log(archivo)}}>clicK</p>
    </>
  )
}
