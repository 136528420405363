import React from 'react'

export default function Dashboard({metadata}) {
  console.log(metadata)
  return (
    <>
      <div>Dashboard.view</div>
      <a href='/exit'>Salir para afuera</a>
      
    </>
  )
}
