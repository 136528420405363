import React, { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import Ingreso from './Ingreso.view';
import { url } from '../../links/Url.link';
import { FormControl, InputLabel, NativeSelect, Select } from '@mui/material';

export default function Seleccion({theDatos, settheDatos}) {
  const [opline, setopline] = useState({
    operario: '',
    linea: '',
    of: ''
  })
  const [ListOfs, setListOfs] = useState([])
  const [isOfOk, setisOfOk] = useState(false)
  // console.log('url', url)
  const queryFetch = async () => {
    const query = await fetch(url+'/getofs',{
      method: 'POST',
      headers: {
        'authorization': "paico 2021",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        maquina: sessionStorage.getItem('MAQUINA')
      })
    })

    const recuest = await query.json()
    setListOfs(recuest.body)
    console.log(recuest.body)
  }

  useEffect(() => {
    let operario = sessionStorage.getItem('operario') || ''
    let linea = sessionStorage.getItem('linea') || ''
    setopline({
      ...opline,
      operario: operario,
      linea: linea
    })
    queryFetch()
  }, [])

  const handleSetOf = async (e) => {
    setopline({
      ...opline,
      [e.target.name]: e.target.value
    })
    await settheDatos({...opline, [e.target.name]: e.target.value});
    await setisOfOk(true)
    // await handleSeisOk();
  }

  const handleSeisOk = async () => {
    if(opline.of === ''){
      setisOfOk(false)
    }else{
      setisOfOk(true)
    }
  }
  useEffect( async () => {
    handleSeisOk()
  }, [opline])

  return (
    <>
      <div className='container-fluid'>
        <div className='row bg-ariztia d-flex justify-content-end'>
          <div className='text-warning'>
            <span>Usuario: {opline.operario}</span>
          </div>
          <div className=''>
            <img src='/img/logo_ariztia.png' height="60px" />
          </div>
        </div>
      </div>

      <div className='container fondo mt-5 pb-5 rounded-3 fondo'>
        <div className='row'>
          <div className='col-12 col-md-6 text-center'>
            <div className='row p-2 py-3'>
              <div className='col-12'>{opline.linea}</div>
              <div className='col-12 fondo rounded-3 p-2'><img src='/img/cfs650.png' height='300px' /></div>
              <div className='col-12 py-2'>
                {/* <TextField
                  id="outlined-select-currency"
                  select
                  label="Numero OF"
                  value={opline.of}
                  onChange={(e)=>handleSetOf(e)}
                  name='of'
                  size='small'
                  fullWidth
                >
                  <optgroup label='menu'>
                    <option>item de menu 1</option>
                  </optgroup>
                <MenuItem value='Opcion uno'>Opcion uno </MenuItem>
                {
                  ListOfs.map((data, key)=><MenuItem key={key} value={data._id}>{data._id}</MenuItem>)
                }
                </TextField> */}

                <FormControl
                  fullWidth
                  size='small'
                  value={opline.of}
                  onChange={(e)=>handleSetOf(e)}
                  name='of'
                >
                  <InputLabel htmlFor="grouped-native-select">Numero de OF</InputLabel>
                  <Select native label="Numero de OF" >
                    <option aria-label="None" value="" />
                    {
                      ListOfs.map((data, key)=><optgroup key={key} label={data._id}> {data.of.map((deta, key2)=><option>{deta}</option>)}</optgroup>)
                    }
                  </Select>
                </FormControl>
              </div>
              <div className='col-12'>
                {/* <Button variant='contained' color='info' onClick={()=>console.log(opline)} disabled={isOfOk}>Ingresar Inyectado</Button> */}
                <Link to={'/ingreso'}  className={isOfOk?"btn btn-primary":"btn btn-primary disabled"} opline={opline} >Ingresar Inyeccion</Link>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 '>
            <div className='row text-center d-flex align-items-center justify-content-center h-100 text-white'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-12 col-md-6 text-danger'>
                    <Link to={'/'}className="btn btn-primary w-100"><ChangeCircleOutlinedIcon/> <br/>Cambiar Linea<br/> o Usuario</Link>
                  </div>
                  <div className='col-12 col-md-6 text-danger mt-3'>
                    <Link to={'/carga'}className="btn btn-primary w-100"><UploadFileOutlinedIcon/> <br/>Cargar Archivo</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
