import AccountCircle from '@mui/icons-material/AccountCircle'
import PasswordIcon from '@mui/icons-material/Password';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react'
import './Login.css'
import url from '../../links/Url.link'
import {AuthContext} from '../../App'
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';

export default function Login() {
const [valores, setValores] = useState({
  user:'',
  password:'',
  showPassword: false,
  isSubmit: false,
  errorMessage: null
})

const handleChange = (prop) => (event) => {
  setValores({ ...valores, [prop]: event.target.value });
};

const handleClickShowPassword = () => {
  setValores({
    ...valores,
    showPassword: !valores.showPassword,
  });
};

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

const {dispatch} = React.useContext(AuthContext);


const handleFormSubmit = event => {
  //console.log(url.url)
  fetch(url.url+'/signin',{
    method: 'POST',
    headers: {
      'authorization': "paico 2021",
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user: valores.user,
      clave: valores.password
    })
  })
  .then(res => res.json())
  .then(resp => {
    console.log(resp)
    if(resp.data.success) {
      dispatch({
        type: "LOGIN",
        payload: {
          user: resp.data.user,
          token: resp.data.token,
          rut: resp.data.rut,
        }
      })
      window.location.href = '/'
    } else {
      setValores({
        ...valores,
        errorMessage: resp.data.error,
      });
      //console.log(valores)
    }
  })
};

// const LoginGuestComponent_ = async () => {
//   fetch(url+'/guestin',{
//     method: 'POST',
//     headers: {
//       'authorization': "paico 2021",
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     }
//   })
//   .then(res=>res.json())
//   .then(res=>{
//     if(res.data.success) {
//       dispatch({
//         type: "LOGIN",
//         payload: {
//           user: res.data.rol,
//           token: res.data.token
//         }
//       })
//     } else {
//       setData({
//         ...data,
//         isSubmit: false,
//         errorMessage: "Error en servidor. intentelo mas tarde"
//       })
//     }
//   })

// }

  return (
    <div className="container">
      <div className="row" style={{height: '100vh'}}>
        <div className="col-12 col-md-6 d-flex align-items-center text-center justify-content-center">
          <img src="/img/logo_ariztia.png" alt="Ariztia" />
        </div>
        <div className="col-12 col-md-6 px-3 fondo d-flex align-items-center text-center justify-content-center bsdw-login " >
          <div className="row">
            <div className="col-12 pt-3 text-left">
              <h4>Login</h4>
            </div>
            <div className="col-12 pt-3">
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="input-username">
                  Usuario
                </InputLabel>
                <Input
                  type="text"
                  value={valores.usuario}
                  onChange={handleChange('user')}
                  id="input-username"
                  startAdornment={
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="col-12 pt-3">
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="input-password">Password</InputLabel>
              <Input
                id="input-password"
                type={valores.showPassword ? 'text' : 'password'}
                value={valores.password}
                onChange={handleChange('password')}
                startAdornment={
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {valores.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            </div>
            <div className="col-12">
              {valores.errorMessage?
              <small className="text-danger">{valores.errorMessage}</small>
              :null
              }
            </div>
            <div className="col-12 pt-5 text-right">
              <Button fullWidth variant="contained" size="small" endIcon={<AssignmentIndIcon />} onClick={handleFormSubmit}>Entrar</Button>
              <Button className="mt-3" size="small" href="/forgetpass" endIcon={<ContactSupportTwoToneIcon />}>Recuperar Password</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
