import {BrowserRouter, Routes} from 'react-router-dom'
import { Route } from 'react-router';
import Main from './views/Main.view'
import React, { createContext, useEffect, useReducer, useState } from 'react';
import Login from './views/session/Login.view';
import Seleccion from './views/ingreso/Seleccion.view';
import Ingreso from './views/ingreso/Ingreso.view';
import Dashboard from './views/admin/Dashboard.view';
import Exit from './views/session/Exit.view';
import CargaArchivo from './views/ingreso/CargaArchivo.view';

export const AuthContext = createContext()
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  rut: null
}

const reducer = (state, action) =>{
  switch (action.type) {
    case 'LOGIN':
      sessionStorage.setItem('user', action.payload.user)
      sessionStorage.setItem('token', action.payload.token)
      sessionStorage.setItem('rut', action.payload.rut)
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        rut: action.payload.rut,
      }

    case 'LOGOUT':
      sessionStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        rut: null
      }

    default:
      return state
  }
}
function App() {

  const [state, dispatch] = useReducer(reducer, initialState)
  const [theDatos, settheDatos] = useState({
    operario: '',
    linea: '',
    of: ''
  })

  useEffect(() => {
    // console.log('state', state)
    const user = sessionStorage.getItem('user') || null
    const token = sessionStorage.getItem('token') || null
    const rut = sessionStorage.getItem('rut') || null
    // console.log('state',state)
    if(token){
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          token,
          rut
        }
      })
    }
  }, [])

  if(!state.token){
    
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Main autentication={state.isAuthenticated} />} />
              <Route path='/login' element={<Login />} />
              <Route path='/seleccion' element={<Seleccion theDatos={theDatos} settheDatos={settheDatos} />} />
              <Route path='/ingreso' element={<Ingreso theDatos={theDatos} />} />
              <Route path='/carga' element={<CargaArchivo />} />
              <Route path='*' element={<Main />} />
            </Routes>
          </BrowserRouter>
        </>
      </AuthContext.Provider>
    )
  } else {
    return (
      <AuthContext.Provider
        value={{state, dispatch}}
      >
        <>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Dashboard metadata={state} />} />
              <Route path='*' element={<Dashboard metadata={state} />} />
              <Route path='/exit' element={<Exit />} />
            </Routes>
          </BrowserRouter>
        </>
      </AuthContext.Provider>
      )
  }

}

export default App;
